.mxr__whoweare h3.mxr__section-title {
    text-align: left;
}

.mxr__section-title.align-left {
    text-align: left;
}

.mxr__whoweare.mxr__section {
    /* padding: 80px 0px 120px 0px !important; */
}

.mxr__whoweare .about-img-sec {
    position: relative;
    height: 100%;
}

.mxr__whoweare .about-image {
    float: left;
}

.about-image:nth-child(2) {
    float: right;
    position: absolute;
    top: 70px;
    right: 0;
}

.mxr__vision.mxr__section {
    padding-top: 150px !important;
    padding-right: 50px;
    margin-right: 0;
    padding-bottom: 150px !important;
    padding-left: 50px;
    margin-left: 0;
}

.mxr__vision h3.mxr__section-title {
    color: #fff;
}

.mxr__vision p {
    color: #fff;
    text-align: center;
    font-size: 20px;
}

.mxr__secretsauce .card {
    height: 380px;
    padding: 30px 15px 15px;
    text-align: center;
    margin: 5%;
}

.mxr__secretsauce .card svg {
    font-size: 5.96px;
    line-height: 71.92px;
    height: 73.92px;
    width: 73.92px;
    align-self: center;
    border-color: #3e3e3e;
    margin-bottom: 15px;
    border-radius: 50%;
    background-clip: inherit;
    border-width: 1px;
    border-style: solid;
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 10px;
    margin-top: 2px;
}

.mxr__secretsauce p.card-text {
    color: #777777 !important;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgb(242 242 242) !important;
    border-radius: 0.25rem;
}

.mxr__secretsauce .card-title.h5 {
    font-size: 24px !important;
    margin-bottom: 15px !important;
}

.mxr__timeline.mxr__section {
    background-image: url("../../assets/images/Innerbanner/blue-background2.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    box-shadow: 0px 0px;
    border-width: 0px 0px 0px 0px;
    border-color: #3e3e3e;
    border-style: solid;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.mxr__timeline h3.mxr__section-title {
    color: #fff;
    text-align: left;
}

.mxr__timeline .vertical-timeline-element-content.bounce-in {
    background: #ff000000 !important;
    color: #fff;
}

.mxr__timeline p {
    color: #fff;
}

.mxr__timeline .vertical-timeline-element--work.vertical-timeline-element {
    display: -webkit-inline-box;
}

.mxr__whoweare .col-lg-7.col-sm-12 {
    padding-top: 7%;
}

.mxr__whoweare .col-lg-5.col-sm-12 img {
    width: 100%;
}

.vertical-timeline .vertical-timeline-element-content.bounce-in {
    display: flex;
}

.mxr__section.mxr__partnerSec {
    background-color: #f8faff;
}

.partnership-section svg {
    color: #1292ee;
}

.partnership-section svg {
    margin-right: 10px;
}

.mxr__partnershipList {
    list-style: none;
    margin: 0;
    padding: 0;
    min-width: 300px;
    color: #777;
}

.mxr__whoWeAre-content {
    /* padding-left: 50px; */
    padding-top: 108px;
}

.partnership-section img {
    width: 100%;
    /* height: 100%; */
    border-radius: 10px;
    /* border:1px solid #1292ee; */
    /* padding: 5px; */
    /* margin-bottom: 37px; */
}

.mxr__partnership-leftSec,
.mxr__partnership-rightSec {
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 50px;
    /* align-items: center; */
}

.mxr__partnership-leftSec {
    align-items: end;
}

.mxr__partnership-rightSec {
    align-items: start;
}

.mxr__partnership-centerSec {
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
    .mxr__vision {
        padding: 30px 30px 30px 30px !important;
    }

    .mxr__whoweare.mxr__section {
        padding: 50px 30px 0px 30px !important;
    }

    .mxr__readytotalk.mxr__section {
        padding: 0;
    }

    .mxr__footer-top {
        padding: 0 !important;
    }

    .mxr__footer .col-lg-3.col-12 {
        padding-top: 23px;
    }

    .internal_page_banner>div {
        min-height: 332px;
    }

}

@media screen and (max-width: 500px) {

    .mxr__section-title.align-left {
        margin-top: 40px;
    }

    .partnership-section img {

        margin-top: 15px;
    }
}

@media (min-width:300px) and (max-width: 450px) {
    .about-image:nth-child(2) {
        float: right;
        position: absolute;
        margin-right: 63px;
        margin-top: 33px;
        margin-left: -20px;
    }
}

.timeline-right-image img {
    margin-left: 10px !important;
}