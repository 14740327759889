 /* .keyFeaturesCase li {
   float: left;
   color: #777;
   font-size: 16px;
   line-height: 1.6;
   font-weight: 400;
 } */

 aside,
 .aside {
   /* font-family: "Abril Fatface", serif; */
   font-size: 0.9em;
   margin-bottom: 1.5em;
   background: #eff0f3;
   margin-top: 70px;
 }

 aside header,
 .aside header {
   background: #edeef0;
   font-weight: bold;
 }

 aside ul,
 .aside ul {
   margin: 0 0.75em 0 1.5em;
   padding: 30px;
 }

 .module,
 .two {
   padding: 0.5em;
 }

 .module header,
 .two header {
   padding: 0.25em 0.5em 0.5em;
   margin: -0.5em -0.5em 0.5em;
 }

 figure img {
   float: left;
   width: calc(100% - 30%);
   margin-bottom: 1.5em;
 }

 figure figcaption {
   float: right;
   width: 27%;
   /* font-family: "Source Sans Pro", serif; */
   font-size: 0.9em;
   color: rgba(0, 0, 0, 0.5);
   line-height: 1.3;
 }

 figure.full img {
   float: none;
   /* width: auto; */
   margin: 0;
 }

 figure.full figcaption {
   margin-top: 0.75em;
 }

 .patelco {
   text-align: center;
   /* border-bottom: 2pt dotted #f58e84; */
 }

 .patelco img {
   padding-bottom: 5px;
   width: 516px;
   height: auto;
 }

 .simple {
   text-align: center;
   padding: 10px;
   font-size: 14pt;
   /* background: #edeef0; */
   /* background: #edeef0; */
 }

 .simple img {
   padding-bottom: 10px;
   margin-top: 10px;
 }

 .simple figcaption {
   padding: 10px;
   border-top: 1px solid black;
   margin-bottom: -10px;
 }

 .under figure,
 .wireframe figure {
   margin-top: 30px;
 }

 .con {
   text-align: center;
   padding: 5pt;
   border: 1pt solid #edeef0;
   background: #edeef0;
   /* font-family: "Abril Fatface", serif; */
 }

 .pictures {
   text-align: center;
   margin-bottom: 20px;
   font-size: 8pt;
 }

 .secondheader {
   font-size: 20pt;
   color: rgba(96, 101, 119, 0.8);
 }

 .aside1 {
   /* font-family: "Abril Fatface", serif; */
   font-size: 0.9em;
   margin-bottom: 1.5em;
   /* background: #f1efef; */
 }

 .aside1 header {
   background: #f1efef;
   font-weight: bold;
 }

 .aside1 ul {
   margin: 0 0.75em 0 1.5em;
 }

 .aside1 li {
   /* font-family: "Source Sans Pro", serif; */
 }

 h4 {
   color: #606577;
   font-size: 14pt;
 }

 .text-black p {
   color: black;
 }

 /* .implementationMargin {
   margin-top: 80px !important;
 } */

 .case__section-title {
   margin: 0px;
   color: black;
   font-size: 2em !important;
   line-height: 1.5;
   text-align: left;
   font-weight: 500;
   padding-bottom: 1.5rem;
   margin-top: 19px;
 }

 .chatbotCase {
   margin-top: -20px;
 }