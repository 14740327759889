/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .mxr-reseach-secright img.w-100 {
    height: 100%;
    display: none;
  }

  .main_project img {
    width: 100%;
  }

  .left_instruction h3.mxr__section-title {
    font-size: 15px !important;
    padding-bottom: 2px;
    line-height: 20px;
  }

  .left_instruction p {
    color: #777;
    font-size: 11px;
    line-height: 1.6;
    font-weight: 400;
  }

  .mxr__contact button {
    background: #3393ab !important;
    font-size: 15px !important;
    color: #ffff !important;
    font-weight: 600;
    border-radius: 4px !important;
    padding: 9px 17px !important;
    margin-top: 10px;
    border: unset;
    width: 34%;
  }

  .mxr__advance2 img {
    width: 100%;
  }

  .mxr__whyshouldunity img {
    width: 100%;
  }

  .simple_tabs img {
    width: 100%;
  }

  .left_instruction .list-group-item {
    display: flex;
    padding: 16px;
  }

  .bottm_bar p {
    display: -webkit-box;
  }

  #basic-navbar-nav.navbar-collapse.collapse li.menu-items {
    padding: 18px 2px 18px 19px;
  }

  .col-md-6.col-sm-12.mxr-reseach-secright {
    width: 100%;
  }

  .mxr__whyshouldunity img {
    width: 100%;
  }

  .mxr__whyshould img {
    width: 100%;
  }

  .carousel-fade video {
    /* width: 100% !important; */
    display: inline-grid;
  }

  .carousel-fade .carousel-inner {
    height: 890px;
  }

  .mxr__footer-top img {
    width: 149px;
  }

  .mxr__design .col-md-6 {
    flex: 0 0 auto;
    width: 100%;
  }

  .mxr__promise img.w-100 {
    display: none;
  }

  .mxr__cta2.mxr__section {
    padding-bottom: 50px;
  }

  .mxr__clients .col-lg-4.col-md-4.col-sm-12 {
    width: 100%;
  }

  .mxr__clients .col-lg-8.col-md-8.col-sm-12 {
    width: 100%;
  }

  .landscap_left_sec img {
    width: 100%;
  }

  .mxr__design img {
    /* display: none; */
  }

  .vertical_tabs img {
    width: 100%;
  }

  .mxr__promise .col-md-6.col-sm-12 {
    width: 100%;
  }

  .mxr__clients img {
    display: none;
  }

  .mxr__footer-top .col-lg-3.col-sm-6.col-12 {
    margin-top: 20px;
  }

  .mxr__promise.mxr__section {
    padding-bottom: 50px;
  }

  /* .mxr__stats-numbers .col-md-3 {
        flex: 0 0 auto;
        width: 50%;
        margin-bottom: 2px;
    } */

  .mxr__stats-numbers .col-md-3 {
    flex: 0 0 auto;
    width: 50% !important;
    margin-left: 182px;
    padding: 20px;
  }

  .mxr__cta2 img.w-100 {
    display: none;
  }

  .mxr__cta2 .col-lg-6 {
    width: 100%;
  }

  .carousel.slide.carousel-fade .carousel-caption {
    width: 70% !important;
  }

  .mxr__clients h3.mxr__section-title {
    text-align: center;
  }

  .carousel-fade img.d-block.w-100 {
    height: 500px !important;
  }

  .mxr__stack-list label {
    display: inline-block;
    font-size: 12px;
  }
}

/*  IPAD Portrait*/
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .mxr__features .card {
    height: 500px;
  }

  .mxr__blog .col-lg-4 {
    margin-bottom: 37px;
  }

  .mxr__hireus img {
    /* display: none; */
  }

  .mxr__help-icon img {
    margin: 10px 0 20px;
    width: 80px !important;
  }

  .mxr__hireus .col-md-6.col-sm-12 {
    width: 100%;
  }

  .mxr__stack-list li {
    width: 30%;
    text-align: center;
    color: #383838;
    font-weight: 600;
  }

  .mxr__header div#basic-navbar-nav {
    position: absolute;
    top: 0;
    left: 0;
    background: #1292ee;
    z-index: 1111;
    width: 80%;
  }

  .mxr__hireus h3.mxr__section-title {
    text-align: left;
    padding-top: 0%;
  }

  .mxr__industries-icons {
    padding-left: 0;
  }

  #basic-navbar-nav.navbar-collapse.collapse li.menu-items {
    overflow: VISIBLE;
    z-index: 9999;
    list-style: none;
    padding: 0 !important;
  }

  .mxr__header .mxr__header-navbar .navbar-nav a.active {
    color: #ffffff !important;
    border-bottom: unset !important;
    /* background: #1292ee; */
  }

  .navbar-nav .menu-items {
    padding: 0px 0px !important;
    border-bottom: 1px solid #fff;
  }

  .menu-items svg {
    position: absolute;
    right: 10px;
    top: 11px;
  }

  .mxr__header-navbar ul.dropdown {
    background-color: #ffffff !important;
  }

  .navbar-nav .menu-items {
    padding: 0px 0px !important;
    border-bottom: 1px solid #fff;
  }

  .mxr__header-navbar ul.dropdown {
    background-color: #1292ee;
    display: none;
    max-width: none;
    position: unset !important;
    top: unset;
    width: auto;
    z-index: unset;
  }

  .mxr__header-navbarmegamenu ul {
    display: inline-table;
    padding: 5px 10px 6px 8px;
    list-style-type: none;
  }

  .mxr__header-navbar .nav-link {
    display: block;
    padding: 7px 18px 7px 18px !important;
    color: #0d6efd;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .mxr__solution .card .card-body {
    padding: 0;
    height: 300px;
  }

  .mxr__advance2 .vertical-timeline-element-content-arrow {
    height: 163px;
  }

  .mxr__secretsauce .card {
    height: 600px;
  }

  .timeline-right-image img {
    display: none;
  }

  .mxr__advance2 img {
    display: none;
  }

  .mxr__advance2 .col-md-5.col-sm-12 {
    width: 100%;
  }

  .mxr__advance2 .col-md-7.col-sm-12 {
    width: 100%;
  }

  .bg_3steps .col-md-4.col-sm-12 {
    text-align: center;
    padding: 13px;
  }

  .mxr__stack-list label {
    display: inline-block;
    font-size: 12px;
  }
}

/*  IPAD Landscape*/
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .mxr__features .card {
    height: 415px;
  }

  .mxr__hireus img {
    width: 100%;
  }

  .mxr__solution .card .card-body {
    padding: 0;
    height: 241px;
  }

  .mxr__features .card {
    height: 415px;
  }

  .mxr__secretsauce .card {
    height: 440px;
  }

  .timeline-right-image img {
    width: 100%;
  }

  .mxr__header .dropdown li.menu-items {
    padding: 0px 19px 0px 0px !important;
  }

  .mxr__whyshouldunity img {
    width: 100%;
  }

  .dropdown a.nav-link {
    font-size: 13px;
    color: #424242 !important;
  }

  .mxr__footer-top button.mxr__section-btn {
    width: 100%;
  }

  .mxr__stack-list label {
    display: inline-block;
    font-size: 12px;
  }
}

/*  IPAD pro Landscape*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .mxr__promise-content {
    padding: 3px 25px 25px 0px;
  }

  .mxr__cta2-content {
    padding: 19px 0px;
  }

  .mxr__features .card {
    height: 430px;
  }

  .mxr__solution .card .card-body {
    padding: 0;
    height: 260px;
  }

  .mxr__footer-top button.mxr__section-btn {
    width: 100%;
  }

  .mxr__stack-list label {
    display: inline-block;
    font-size: 12px;
  }
}

/*  IPAD pro portrait*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .mxr__features .card {
    height: 430px;
  }

  .mxr__hireus img {
    width: 100%;
  }

  .mxr__stack-list label {
    display: inline-block;
    font-size: 12px;
  }

  .mxr__solution .card .card-body {
    padding: 0;
    height: 260px;
  }

  .dropdown a.nav-link {
    font-size: 11px;
    color: #424242 !important;
  }

  .dropdown a.nav-link {
    font-size: 11px;
    color: #424242 !important;
  }

  .mxr__header-navbarmegamenu h3 {
    color: #333;
    font-family: Nunito;
    font-size: 13px;
  }

  .mxr__header .dropdown li.menu-items {
    padding: 0px 0px 0px 0px !important;
  }

  .timeline-right-image.col-lg-6.col-sm-12 img {
    width: 500px;
  }

  .mxr__secretsauce .card {
    height: 430px;
  }

  .mxr__footer-top button.mxr__section-btn {
    width: 100%;
  }

  .mxr__header-navbar div#basic-navbar-nav {
    position: relative;
    margin-left: -10px;
  }

  .bottm_bar.row {
    background: whitesmoke;
    padding: 24px 0px;
    width: 68%;
    margin-left: 155px;
  }
}

/* office IPAD pro portrait*/
@media only screen and (min-device-width: 700px) and (max-device-width: 1024px) {
  .mxr__features .card {
    height: 470px !important;
  }

  .dropdown a.nav-link {
    color: #424242 !important;
    font-size: 11px;
  }

  .mxr__industries-icon_box h4 {
    color: var(--color-text);
    font-size: 12px;
    font-weight: 400;
  }

  .mxr__whoweare .col-lg-7.col-sm-12 {
    padding-top: 0;
  }

  .mxr__stack-list label {
    display: inline-block;
    font-size: 12px;
  }

  .mxr__stack-list li {
    width: 30%;
  }

  .timeline-right-image.col-lg-6.col-sm-12 img {
    width: 500px;
    display: none;
  }

  .mxr__hireus img {
    width: 100%;
  }

  .mxr__solution .card .card-body {
    padding: 0;
    height: 285px;
  }

  .mxr__footer-top button.mxr__section-btn {
    width: 100%;
  }

  .Ai-Image2 {
    border-radius: 20px;
    margin-right: 0px;
    margin-top: 40px;
  }

  .tranformImg {
    margin-left: -10px;
  }
}

/* phone lands*/
@media (min-width: 576px) and (max-device-width: 766px) {
  .mxr__industries-icons {
    padding-left: 0px;
  }

  .mxr__whyshould img {
    width: 100%;
  }

  .mxr__stats-number-sec {
    margin-right: -1px;
  }

  .time_line-icon_text {
    text-align: left;
  }

  .mxr__footer-top button.mxr__section-btn {
    width: 100%;
  }

  .mxr__industries-icon_box {
    padding: 14px;
  }

  .mxr__clients .col-lg-6.col-md-6.col-sm-6 {
    text-align: center;
  }

  .mxr__clients h3.mxr__section-title {
    text-align: center;
  }

  .carousel-fade img.d-block.w-100 {
    height: 450px;
  }

  .carousel-fade .carousel-inner {
    height: 400px;
  }

  .container,
  .container-sm {
    max-width: 739px;
  }

  .mxr__industries-icon_box h4 {
    font-size: 12px;
  }

  .mxr__blog-post-list .col-lg-4 {
    margin-bottom: 12px;
  }

  .social_links_bottom.col-md-6 {
    display: -webkit-inline-box;
  }

  .carousel-fade video {
    height: auto !important;
    width: 100%;
  }

  .mxr__industries-icon_box h4 {
    color: var(--color-text);
    font-size: 12px;
    font-weight: 400;
  }

  .mxr__features .card {
    height: 350px !important;
  }


}

/* ipxel 5 lands*/
@media screen and (max-width: 851px) {
  .mxr__header div#basic-navbar-nav {
    position: absolute;
    top: 0;
    left: 0;
    background: #1292ee;
    z-index: 1111;
    width: 80%;
    margin-left: 52px;
    margin-top: 50px;
    border-radius: 10px;
  }

  .mxr__header .mxr__header-navbar .navbar-nav div .nav-link {
    padding: 7px 18px !important;
  }

  .timeline-right-image img {
    display: none;
  }

  .mxr__header-navbar ul.dropdown {
    background-color: #1292ee;
    display: none;
    max-width: none;
    position: unset !important;
    top: unset;
    width: auto;
    z-index: unset;
  }

  .mxr__header-navbar ul.dropdown {
    background-color: #ffffff !important;
  }

  #basic-navbar-nav.navbar-collapse.collapse li.menu-items {
    overflow: VISIBLE;
    z-index: 9999;
    list-style: none;
    padding: 0 !important;
  }

  .navbar-nav .menu-items {
    padding: 0px 0px !important;
    border-bottom: 1px solid #fff;
  }

  .mxr__secretsauce .card {
    height: auto;
    padding: 30px 15px 15px;
    text-align: center;
    margin: 5%;
  }

  .mxr__header-navbar .nav-link {
    display: block;
    padding: 7px 18px 7px 18px !important;
    color: #0d6efd;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .menu-items svg {
    position: absolute;
    right: 6px;
    top: 11px;
  }
}

@media (min-width: 2000px) and (max-device-width: 2700px) {
  .carousel-inner video {
    height: 100%;
    width: 100%;
  }
}

@media (min-width: 1020px) and (max-width: 1030px) {
  .mxr__features .card {
    height: 365px !important;
  }
}

@media (min-width: 760px) and (max-width: 950px) {
  .mxr__features .card {
    height: 410px !important;
  }
}

@media (min-width: 810px) and (max-width: 1025px) {
  .mxr__help-icon img {
    margin: 10px 0 20px;
    width: 80px !important;
  }

  .about-us-benefits {
    margin-top: 420px;
    max-width: 90%;
    margin-bottom: -90px;
  }

  .mxrAbout {
    margin-left: 218px !important;
    margin-top: -37px;
  }
}

@media (min-width: 350px) and (max-width: 1023px) {
  .bottomMargin {
    margin-bottom: 30px;
  }
}


@media (min-width: 768px) and (max-width:1023px) {
  .whoweImag {
    width: 620px !important;
  }

  .vscards {
    height: 400px;
    margin-bottom: 50px;
  }

  .vrPlatformImg {
    width: 670px !important;
  }

  .vrCaseImg {
    width: 385px;
    position: relative;
    margin-top: 118px;
    margin-right: 20px;
  }

  .mxr__capabilities {
    padding: 0px !important;
    padding-top: 30px !important;
  }

  .MrAboutPara {
    margin-top: -110px !important;
    margin-bottom: 130px;
  }

  .immersiveImg {
    width: 96% !important;

  }

  .immersiveaboutPara {
    margin-bottom: 120px;
    /* margin-top: -109px !important; */
  }

  .Aboutwhatwemargin {
    margin-top: 100px;
  }

  .about-us-image img {
    max-width: 100%;
    height: auto;
    margin-left: 54px;
  }

  .backgroundDot {
    margin-left: 312px;
    margin-top: 190px;
  }

  .trustLogos {
    flex: 0 0 auto;
    margin-left: 30px;
    margin-right: 110px;
    width: calc(100% / 4);
    width: 111px;
  }
}

/* ipad mini s */
@media (min-width: 750px) and (max-width:780px) {

  .immersiveaboutPara {
    margin-bottom: 170px;
    margin-top: -109px !important;
  }

  .e-learningCustom {
    margin-top: 40px !important;
  }

  .textimgcustom {
    width: 346px;
    margin-top: 128px;
  }

  .mxr__advance2 .vertical-timeline-element-content-arrow {
    height: 10px !important;
  }

  .marginCapab {
    padding-bottom: 30px !important;
  }

  .aboutwhatwedoImg {
    height: 226px !important;
    margin-top: 68px !important;
  }

  .aboutMXRimg {
    height: 245px !important;
    margin-top: 38px;
  }

  .eventimg {
    width: 302px;
    margin-top: 80px;
    border-radius: 20px;
  }

  .banner-text h1 {
    font-size: 20px;
    margin-top: 60px;
  }

  .tranformImg {
    margin-left: -10px;
  }

  .bottm_bar.row {
    background: whitesmoke;
    padding: 24px 0px;
    width: 100%;
    margin-left: 0px;
  }

  .whoWEmargin {
    margin-left: -30px;
    width: 100% !important;
  }

  .chatbotSection {
    margin-bottom: 40px;
  }

  .studybot {
    height: 348px !important;
    margin-left: -30px;

  }

  .mxrAbout {
    margin-left: 176px !important;
    margin-top: -175px;
  }

  .about-us-benefits {
    text-align: left;
    margin-top: 380px !important;
    margin-bottom: 0px;
  }

  /* .carousel-fade .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 899px;
  } */
  .cta2btnHome {

    margin-left: 257px !important;
    text-align: center !important;
  }

  .mixImage img {
    width: 648px;
    margin-left: 27px;
    margin-top: 50px;
  }

  .arAboutImg {
    margin-left: 139px;
  }

  .arAboutImg2 {
    margin-top: 46px;
  }

  .partneringAiSection {
    width: 88%;
  }

  .trustLogos {
    flex: 0 0 auto;
    margin-left: 30px;
    margin-right: 110px;
    width: calc(100% / 4);
    width: 85px;
  }

  .trusted-by-logos {
    display: grid;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 20px 0;
  }

  .genAiImage {
    height: 441px !important;
    text-align: center;
    margin-left: -28px;
    margin-top: 200px;
  }

  .immersiveAbout img {
    width: 400px;
    margin-top: 172px;
    margin-left: -52px;
  }

  .insightXrApplication {
    display: none;
  }

  .xrImage3 {
    width: 290px !important;
  }

  .slideCard {
    height: fit-content;
  }

  .slide .carousel-caption h3 {
    font-weight: 750;
    font-size: 40px !important;
    line-height: 49px !important;
    font-family: Nunito !important;
    /* text-transform: uppercase; */
    color: #fff;
    text-align: center;
  }

  .whyChoose360 {
    width: 320px !important;
  }

  .InsightKnowledgeImg {
    margin-top: 100px;
    width: 560px;
    margin-bottom: 40px;
    margin-left: 70px;
  }

  .InsightPhotoSectionImg {
    width: 550px;
    margin-left: 60px;
    margin-top: -40px;
  }
}

/* ipad mini  */

/* ipad air  */
@media (min-width: 820px) and (max-width:850px) {

  .marginCapab {
    padding-bottom: 30px !important;
  }

  .immersiveAbout {
    height: 484px !important;
  }

  .textimgcustom {
    width: 346px;
    margin-top: 128px;
  }

  .MrAboutPara {
    margin-top: -30px !important;
    margin-bottom: 130px;
  }

  .backgroundDot {
    margin-left: 312px;
    margin-top: 81px;
  }

  .about-us-image img {
    max-width: 100%;
    height: auto;
    margin-left: 116px;
  }

  .banner-text h1 {
    font-size: 20px;
    margin-top: 60px;
  }

  .eventimg {
    width: 318px;
    margin-top: 80px;
    border-radius: 20px;
  }

  .bottm_bar.row {
    background: whitesmoke;
    padding: 24px 62px;
    width: 100%;
    margin-left: 0px;
  }

  .studybot {
    height: 348px !important;
    margin-left: -30px;

  }

  .about-us-section {

    margin-bottom: 46px;
  }

  .Vr360Img img {
    width: 420px !important;
    margin-left: -40px;
    margin-top: -5px;
  }

  .trusted-by-logos {
    display: grid;
    justify-content: center;
    align-items: center;
    gap: 44px;
    padding: 20px 0;
  }

  .immersiveAbout img {
    width: 410px;
    margin-top: 232px;
    margin-left: -60px;
  }

  .genAiImage {

    height: 480px !important;
    margin-left: -41px;
    margin-top: 180px;

  }

  .Ai-ImagePartner {
    width: 83% !important;
    margin-top: 140px;
    margin-left: 45px;
  }

  .Ai-Image {
    margin-top: 113px;
  }

  .insightXrApplication {
    display: none;
  }

  .XrAbout img {
    width: 341px !important;
    margin-top: 120px;
    margin-left: 4px;
  }

  .slide .carousel-caption h3 {
    font-weight: 750;
    font-size: 40px !important;
    line-height: 49px !important;
    font-family: Nunito !important;
    /* text-transform: uppercase; */
    color: #fff;
    text-align: center;
  }

  .xrImage3 {
    width: 300px !important;
  }

  .InsightPhotoSectionImg {
    width: 648px;
    margin-left: 40px;
    margin-top: -40px;
  }
}

/* end ipad air  */


/*START ipad pro */
@media (min-width: 1024px) and (max-width:1366px) {

  .InsightPhotoSectionImg {
    width: 648px;
    margin-left: 160px;
    margin-top: -40px;
  }

  .partnership-section img {
    width: 100%;
    /* height: 100%; */
    border-radius: 10px;
    /* border: 1px solid #1292ee; */
    /* padding: 5px; */
    margin-top: 30px;
    margin-bottom: 58px;
  }

  .whyChoose360 {
    width: 394px !important;
  }

  .slide .carousel-caption h3 {
    font-weight: 750;
    font-size: 42px !important;
    line-height: 49px !important;
    font-family: Nunito !important;
    /* text-transform: uppercase; */
    color: #fff;
    text-align: center;
  }

  .insightXrApplication {
    width: 380px;
    margin-left: -66px;
    margin-top: -550px;
  }

  .XrAbout img {
    width: 445px !important;
    margin-top: 60px;
  }

  .Ai-ImagePartner {
    width: 83% !important;
    margin-top: 140px;
    margin-left: 45px;
  }

  .mixImg {
    margin-top: 0px;
    height: 500px !important;

  }

  .banner-text h1 {
    font-size: 2rem;
    margin-top: 40px;
  }

  .MrAboutPara {
    margin-top: -76px !important;
  }

  .Vr360Img {
    margin-top: 96px !important;
  }

  .vr360advanceImg img {
    margin-top: 140px;
  }

  .immersiveImg {
    width: 96% !important;

  }

  .whyImgImmersive {
    width: 880px;
  }

  .textimgcustom {
    width: 446px;
    margin-top: 68px;
  }

  .arSection {
    /* margin-bottom: 50px; */
  }

  .vrCaseImg {
    width: 510px;
    margin-top: 24px;
  }

  .vr360Para {
    margin-top: -20px;
  }

  .aboutMXRimg {
    height: 280px !important;
  }

  .aboutwhatwedoImg {
    height: 287px !important;
    margin-top: 68px !important;
  }

  .Aboutwhatwemargin {
    margin-top: 40px;
  }

  .about-us-image img {
    max-width: 100%;
    height: auto;
    margin-left: 34px;
    margin-top: -17px;
  }

  .right-side-images img {
    height: 234px !important;
  }

  .about-us-benefits {
    margin-top: 527px;
    max-width: 90%;
    margin-bottom: -90px;
  }

  .about-us-section {

    margin-bottom: 46px;
  }

  .vscards {
    padding: 0px;
    /* height: 300px;
        text-align: start;
        color: black; */
  }

  .researchimgRight {
    margin-top: 174px;
  }

  .bottm_bar.row {
    background: whitesmoke;
    padding: 24px 33px;
    width: 100%;
    margin-left: 0px;
  }

  .carousel-fade .carousel-inner {
    height: 870px;
  }

  .trustLogos {
    flex: 0 0 auto;
    margin-left: 30px;
    margin-right: 80px;
    width: calc(100% / 4);
    width: 111px;
  }

  .arAboutImg2 {
    width: 430px !important;
    margin-top: 46px;
    margin-left: -20px;
  }

  .arAboutImg {

    margin-left: 0px !important;
  }

  .trusted-by-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px;
    padding: 20px 0;
  }

  .genAiImage {
    height: 548px !important;
    text-align: center;
    margin-left: -4px;
    margin-top: 100px;
  }

  .Ai-Image {
    /* width: 551px; */
    margin-top: 20px;
  }

  .immersiveWhatIsImage2 {
    margin-top: 187px;
  }

  .immersiveAbout img {
    width: 490px;
    margin-top: 62px;
    margin-left: -40px;
  }

  .InsightKnowledgeImg {
    margin-top: 100px;
    width: 820px;
    margin-bottom: 40px;
    margin-left: 70px;
  }

  .blogsHeading {
    margin: 0px;
    color: rgb(255, 255, 255);
    font-size: 30px !important;
    line-height: 1.4;
    font-weight: 750;
    text-transform: uppercase;
    text-align: center;
  }

}

/*END ipad pro */

/* ipad air */
@media (min-width: 820px) and (max-width:1023px) {

  .whoweImag {
    width: 620px !important;
  }

  .whoWEmargin {
    margin-left: -30px;
    width: 100% !important;
  }

  .Aboutwhatwemargin {
    margin-top: 70px;
  }

  .aboutwhatwedoImg {
    height: 225px !important;
    margin-top: 68px !important;
  }

  .aboutMXRimg {
    height: 260px !important;
    margin-top: 25px;
    margin-left: -10px;
  }

  .Vr360Img {
    margin-top: 131px !important;
  }

  .inneraboutvr {
    padding-top: 0px !important;
  }

  .mxr__hireus h3.mxr__section-title {
    text-align: left;
    padding-top: 0%;
  }

  /* .mxr__section {
      padding: 50px;
    } */
  .vr360advanceImg img {
    margin-top: 240px;
  }

  .XrAbout video {
    width: 340px !important;
    margin-top: 70px !important;
  }

  .ExamAbout img {
    width: 340px !important;
    margin-top: 70px !important;
  }

  .mxr__whoweare.mxr__section {
    padding: 63px 0px 10px 0px !important;
  }

  .HomeCsRight {
    font-size: 11px !important;
  }

  .HomeCsRightbtn {

    font-size: 12px !important;
  }

  .mxr__research-icon img {
    width: 74px;
  }

  .mixImage img {
    width: 393px;
    margin-left: -50px;
    margin-top: 179px;
  }

  .arAboutImg {
    width: 380px !important;
    margin-top: -30px;
    margin-left: 140px;
  }

  .arAboutImg2 {
    width: 430px !important;
    margin-top: 46px;
    margin-left: 120px;
  }

  .whyChoose360 {
    width: 320px !important;
  }
}

@media (min-width: 374px) and (max-width:376px) {
  .vscardsVR360 {
    height: 291px !important;
    margin-bottom: 50px;
    padding: 30px;
  }
}



@media (min-width: 350px) and (max-width:450px) {
  .vscardsVR360 {
    height: 291px !important;
    margin-bottom: 50px;
    padding: 60px;
  }

  .vscards {
    height: 470px !important;
    margin-bottom: 50px;
  }

  .vr360Para {
    margin-bottom: 30px !important;
  }

  .e-learningCustom {
    margin-top: 40px !important;
  }

  .accordion-container {
    width: 93%;
    max-width: 600px;
    margin: 0px !important;
  }

  .vr360Picture {
    width: 351px !important;
    margin-left: -33px;
  }

  .marginAfford {
    margin-top: -27px;
  }

  .researchMargin {
    margin-top: -42px !important;

  }

  .titleSpan {
    font-size: 12px;
  }

  .discoverbtn {
    width: 195px;
    font-size: 12px !important;
    height: 35px;
    margin-top: -29px;
  }

  .HomeTitle p {
    max-width: 900px;
    margin: 0 auto;
    word-wrap: break-word;
    white-space: normal;
    line-height: 1.6;
    font-size: 13px;
  }

  .mxr__whoWeAre-content {
    padding-top: 50px;
    /* margin-top: 30px !important; */
  }

  .mxr__section-title {
    margin-top: 42px !important;
    text-align: center !important;
  }

  .eventimg {
    width: 270px;
    margin-top: 80px;
    border-radius: 20px;
  }

  .tranformImg {
    margin-left: 0px;
  }

  .Ai-Image2 {
    border-radius: 20px;
    margin-right: 0px;
    margin-top: -20px;
  }

  .generativePara {
    margin-bottom: 10px !important;
  }

  .bottm_bar.row {
    background: whitesmoke;
    padding: 24px 0px;
    width: 100%;
    margin-left: 0px;
  }

  .immersiveAbout {
    height: 213px !important;
  }

  .processMargin {
    margin-top: -20px !important;
  }

  .about-us-benefits {
    /* max-width: 100%; */
    text-align: left;
    margin-top: 230px !important;
    margin-bottom: 0px;
  }

  .carousel-fade h3.mxr__section-title {
    font-weight: 900 !important;
    font-size: 18px !important;
    /* background: #00000094; */
    padding: 9px;
    width: 100%;
    float: left;
    line-height: 26px !important;
    margin-bottom: -2px;
    margin-top: 27px !important;
  }

  .mxrAbout {
    margin-left: 66px;
    margin-top: -141px;
  }

  .solutionOverviewTittle {
    margin-top: -25px !important;
  }

  .marginMobile {
    margin-top: 0px !important;
  }

  .trustLogos {
    flex: 0 0 auto;
    margin-left: 30px;
    margin-right: 20px;
    width: calc(100% / 4);
    width: 111px;
  }

  .mixImage {
    height: 270px;
    /* margin-left: -50px; */
  }


  .mixImage img {
    width: 340px;
    margin-left: 1px;
  }

  .patelco img {
    padding-bottom: 5px;
    width: 350px;
    height: auto;
  }

  .arAboutImg {
    width: 345px !important;
    margin-top: -34px !important;
  }

  .arAboutImg2 {
    margin-top: 46px !important;
  }

  .slideCard {
    min-width: 90% !important;
    height: 400px !important;
  }

  .blogsHeading {
    margin-top: 60px !important;
    color: rgb(255, 255, 255);
    font-size: 18px !important;
    line-height: 1.4;
    font-weight: 750;
    text-transform: uppercase;
    text-align: center;
    width: 310px;
  }

  .immersiveAbout img {
    width: 350px;
    margin-top: -48px;
    margin-left: 10px;
  }

  .partneringAiSection {
    width: 360px;
  }

  .mxr__cta2 p {
    color: #fff;
    text-align: center;
    /* margin-left: 15px; */
  }

  .cta2btnHome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    float: none;
  }

  .insight-title {
    margin-top: 10px !important;
    color: black;
    font-size: 27px;
    line-height: 1.5;
    text-align: center;
    font-weight: 500;
    padding-bottom: 1.5rem;
  }

  .nhsVideo {
    width: 100%;
    /* margin-left: 310px; */
  }

  .para-img {
    width: 100%;
    margin-bottom: 40px;
    border-radius: 30px;
  }

  .insightXrApplication {
    width: 342px;
    margin-left: 0px;
  }

  .XrDemoBtn {
    margin-top: -56px !important;
  }

  .Ai-ImagePartner {
    width: 342px !important;
  }

  .whyChoose360 {
    width: 310px !important;
  }

  .textimgcustom {
    width: 364px !important;
  }

  .mxr__blog-content img {
    width: 100%;
    height: 207px;
  }
}

@media (min-width: 411px) and (max-width:415px) {
  .vscards {
    height: 420px !important;
  }

  .carousel-fade h3.mxr__section-title {

    /* padding: 16px; */
  }

  .mxrAbout {
    margin-left: 66px;
    margin-top: -167px;
  }
}

@media (min-width: 414px) and (max-width:415px) {
  .vscards {
    height: 390px !important;
  }

  .mxrAbout {
    margin-left: 59px;
    margin-top: -148px;
  }

  .about-us-benefits {
    margin-top: 195px !important;
  }
}

@media (min-width: 300px) and (max-width:450px) {
  .VsBottom {
    border-right: 0px solid rgb(255 255 255) !important;
    /* border-bottom: 1px solid rgb(18, 146, 238); */
    ;
  }

  .motionMargin {
    margin-top: -54px !important;
  }
}

@media (min-width: 300px) and (max-width:450px) {
  .internal_page_banner h1 {
    font-size: 18px;
    margin-top: 40px;
  }

  .internal_page_banner p {
    font-size: 13px;
  }

  .mixImg {
    margin-bottom: -380px !important;
  }

  .mxr__hireus p {
    margin-bottom: 150px;
  }

  .XrAbout video {
    width: 313px !important;
    margin-top: -66px;
    margin-left: 13px;
  }

  .XrAbout img {
    width: 299px !important;
    margin-top: -60px;
    margin-left: 28px;
  }

  .ExpertiseInsight img {
    margin-top: 43px;
    margin-left: -10px !important;
  }


  .XrAbout {
    height: 133px !important;
  }

  .ExamAbout {
    height: 133px !important;

  }

  .researchimgRight {
    margin-top: -60px;
  }

  .aboutMXRimg {
    height: 222px !important;
    margin-left: 24px;
  }


  .aboutwhatwedoImg {
    margin-bottom: 32px;
    height: 238px !important;
    margin-left: 50px;
  }

  .genAiImage {
    height: 441px !important;
    text-align: center;
    margin-left: -4px;
    margin-top: -50px;
    margin-bottom: 40px;
  }


  .missionimg {
    height: 312px !important;
  }

  .mxr__advance2 .vertical-timeline-element-content-arrow {
    height: 10px !important;
  }

  .chatbotSection {
    margin-bottom: -200px;
  }

  .xrImage2 {
    width: 340px !important;
  }

  .xrImage3 {
    width: 340px !important;

  }

  .InsightStat {
    width: 75px !important;
  }

  .slideCard {
    width: 100% !important;
  }

  .InsightKnowledgeImg {
    margin-top: 100px;
    width: 330px;
    margin-bottom: 40px;
  }

  .InsightPhotoSectionImg {
    width: 344px;
    margin-left: 0px;
    margin-top: -40px;
  }

  .career-video {
    width: 350px !important;
  }
}


@media (min-width: 416px) and (max-width:440px) {
  .vscards {
    height: 480px !important;
    padding: 49px !important;
  }
}

@media (min-width: 350px) and (max-width:440px) {
  .vscards {
    height: 480px;
    padding: 49px;
  }

  .arAboutImg2 {
    margin-top: 40px;
    width: 360px !important;
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) {
  .mxr__section-title {
    font-size: 28px !important;
    text-align: center !important;
  }
}

@media (min-width: 2701px) {


  .carousel-inner video {
    height: 100%;
    width: 100%;
  }

  .HomeTitle {
    margin-top: 72px;
  }
}

@media (min-width: 3501px) {

  .HomeTitle {
    margin-top: -98px;
  }
}


/* @media (max-width:100vw) {
  .importantLinksFooter {
    width: calc(100%);
    display: block;
    }
    } */
@media (min-width: 451px) and (max-width:749px) {
  .mxrAbout {
    margin-left: 86px;
    margin-top: -265px;
    width: 470px;
  }

  .HomeTitle p {
    max-width: 900px;
    margin: 0 auto;
    word-wrap: break-word;
    white-space: normal;
    line-height: 1.6;
    font-size: 13px;
  }

  .discoverbtn {
    display: block;
    margin: -19px auto 0;
    text-align: center;
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
  }

}

@media (max-width: 350px) {

  .HomeTitle {
    /* margin-top: -98px; */
  }

  .HomeTitle p {
    max-width: 900px;
    margin: 0 auto;
    word-wrap: break-word;
    white-space: normal;
    line-height: 1.6;
    font-size: 13px;
  }

  .discoverbtn {
    display: block;
    margin: -19px auto 0;
    text-align: center;
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
  }

  .mxrAbout {
    margin-left: 23%;
    margin-top: -265px;
    width: 470px;
  }

  /* .footerLogo {
      width: 222px;
    } */


  .discoverbtn {
    width: 153px;
    font-size: 9px !important;
    height: 30px;
    margin-top: -34px;
  }


  .research-left h2 {
    font-size: 146%;
    color: white !important;
    font-weight: 700;
  }

  .carousel-fade h3.mxr__section-title {
    font-weight: 900 !important;
    font-size: 19px !important;
    /* background: #00000094; */
    padding: 10px;
    width: 100%;
    float: left;
    line-height: 28px !important;
  }
}

@media (min-width: 576px) and (max-width:767px) {

  .carousel-fade .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 645px !important;
  }


  .carousel-fade video {
    height: 868px !important;
  }

  .HomeTitle p {
    max-width: 900px;
    margin: 0 auto;
    word-wrap: break-word;
    white-space: normal;
    line-height: 1.6;
    font-size: 14px;
  }

  .research-left p {
    color: white;
    width: 70vw;
  }
}

@media (min-width: 1366px) and (max-width:1828px) {
  .insightXrApplication {
    width: 380px;
    margin-left: -66px;
    margin-top: -550px;
  }
}



@media (min-width: 851px) and (max-width:1023) {

  .insightXrApplication {
    display: none !important;
  }
}

@media (min-width:1080px) and (max-width:3000px) {
  .slideCard {
    margin-left: 40px;
  }

  .trustedDiv {
    margin-left: 110px;
  }
}

/* styles for medium laptops */
@media screen and (min-width: 1367px) and (max-width: 1600px) {

  .mxrAbout {
    margin-left: 236px;
    margin-top: -91px;
    width: 470px;
  }

  .timeline-right-image.col-lg-6.col-sm-12 img {
    position: relative;
    bottom: -53px !important;
    right: 0;
    width: 119% !important;
  }
}

@media screen and (min-width: 1601) and (max-width: 1835px) {
  .timeline-right-image.col-lg-6.col-sm-12 img {
    position: relative;
    bottom: -223px !important;
    right: 0;
    width: 120% !important;
  }


}

@media screen and (min-width: 781px) and (max-width: 819px) {
  .InsightPhotoSectionImg {
    width: 608px;
    margin-left: 50px;
    margin-top: -40px;
  }


}

@media screen and (min-width: 851px) and (max-width: 1023px) {
  .InsightPhotoSectionImg {
    width: 660px;
    margin-left: 30px;
    margin-top: -40px;
  }
}

@media screen and (min-width: 451px) and (max-width: 767px) {
  .InsightPhotoSectionImg {
    width: 440px;
    margin-left: 40px;
    margin-top: -40px;
  }

  .InsightKnowledgeImg {
    margin-top: 100px;
    width: 449px;
  }


}

@media (min-width:1080px) and (max-width:1400px) {

  .Vr360Img {
    margin-left: -40px;
    width: 200px !important;
  }


  .additionalFeatures {
    margin-left: -100px;
    margin-top: 30px !important;
  }

  .webDesignPhoto {
    margin-left: -30px !important;
  }

  .custom-row {
    display: flex;
    flex-direction: column;

  }

  .InsightKnowledgeImg {
    margin-left: 160px !important;
  }

  .InsightPhotoSectionImg {
    width: 860px;
    margin-left: 100px;
    margin-top: -40px;
  }

  .timeline-right-image.col-lg-6.col-sm-12 img {
    position: relative;
    bottom: -457px;
    right: -46px;
    height: 64%;
  }
}

@media (min-width:660px) and (max-width:1023px) {
  .blogsHeading {
    margin: 0px;
    color: rgb(255, 255, 255);
    font-size: 25px !important;
    line-height: 1.4;
    font-weight: 750;
    text-transform: uppercase;
    text-align: center;
  }
}

/* header toggle */
/* @media (min-width:852px) and (max-width:991px) {


} */


@media (min-width:844px) and (max-width:1400px) {

  .bottm_bar.row {
    background: whitesmoke;
    padding: 24px 119px;
    width: 100%;
    margin-left: 0px;
  }
}

@media (min-width:451px) and (max-width:659px) {
  .blogsHeading {
    margin: 0px;
    color: rgb(255, 255, 255);
    font-size: 18px !important;
    line-height: 1.4;
    font-weight: 750;
    text-transform: uppercase;
    text-align: center;
  }

  .bottm_bar.row {
    background: whitesmoke;
    padding: 24px 16px;
    width: 100%;
    /* margin-left: 294px; */
  }
}

@media (min-width:1400px) and (max-width:1870px) {


  .bottm_bar.row {
    background: whitesmoke;
    padding: 24px 216px;
    width: 100%;
    /* margin-left: 294px; */
  }
}

@media (max-width: 767px) {


  .careerWhatwe {
    width: 100% !important;
    text-align: center;
  }
}