.blog-container {
    padding: 20px;
    background-color: #f9f9f9;
}

.blog-text {
    line-height: 1.6;
    color: #333;
    margin-bottom: 1.5rem;
}

.blog-subtitle {
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: #555;
    font-weight: 600;
}

.blog-list {
    padding-left: 20px;
    margin-bottom: 1.5rem;
}

.blog-list-item {
    margin-bottom: 0.5rem;
    color: #333;
}

.img-fluid {
    width: 100%;
    height: auto;
}

.shadow-sm {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.para-img {
    width: 39vw;
    margin-bottom: 40px;
    border-radius: 30px;
}

.blogPara::first-letter {
    color: #1292ee;
    font-size: 60px
}