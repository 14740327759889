.joinOurCards {
    color: #fff;
    border: 1px solid #1292ee !important;
    padding: 1.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 500px;
    margin-top: 10px;
    background-color: white;
}

.job-title {
    color: #1292ee;
    font-weight: 600;
    margin-bottom: 1rem;
}

.read-more {
    color: #1292ee;
    text-decoration: none;
    font-weight: bold;
}

.job-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin: 1rem 0;
    align-self: center;
}

/* jobs deatails */
.job-posting-container {
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
}

.job-posting-container h4 {
    margin-top: 20px;
}

.job-posting-container a {
    text-decoration: none;
}

.job-posting-container hr {
    margin: 15px 0;
    border: none;
    border-top: 1px solid #cc3300;
}

.unOrderedList ul li {
    color: #777;
}

.jobDetailMargin {
    margin-bottom: 100px;
}

.careerWhatwe {
    align-items: center;
    justify-content: center;
    /* margin-left: 180px; */
}

.career-video {
    width: 550px !important;
}

@media (max-width: 767px) {
    .borderBottom {
        width: 100% !important;
    }

    .job-summary {
        position: relative !important;

    }


}

@media (min-width: 767px) and(max-width: 1024) {

    .job-summary {
        position: sticky !important;

    }

}