.nhsIcons {
    transition: transform 0.3s ease;
}

.nhsIcons:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
}

.nhsVideo {
    width: 640px;

    /* margin-left: 310px; */
}

.nhsVideoSection {
    text-align: center;
}