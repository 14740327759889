.mxr__capabilities ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.mxr__capabilities li.abouticon {
  display: flex;
  align-items: flex-start;
  margin-right: 20px;
  margin-bottom: 20px;
}

.mxr__capabilities li.abouticon img {
  max-width: 80px !important;
  height: auto;
  margin-right: 10px;
}

@media (max-width:450px) {
  .whyImgImmersive {
    width: 292px;
  }
}

.icon_leftside img {
  width: 80px;
}