.HomeTitle {
    text-align: center;
    /* Center text content */
}

.HomeTitle p {
    max-width: 900px;
    margin: 0 auto;
    /* Center the paragraph */
    word-wrap: break-word;
    white-space: normal;
    line-height: 1.6;
    font-size: 25px;
}