@media screen and (max-width: 767px) {
    .mxr__whoweare img {
        width: 100% !important;
    }
}

@media screen and (max-width: 1024px) {
    .mxr__whoweare.mxr__section {
        margin-bottom: -82px !important;
    }
}