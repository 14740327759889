/* .mxr__footer {
  background: #303030;
  color: #fff;
}

.mxr__footer p {
  color: #fff;
}

.mxr__footer a {
  color: #fff;
  padding: 10px 0 0 0;
  font-size: 14px;
  font-weight: 300;
}

.mxr__footer-subscribe button.mxr__section-btn {
  width: 100%;
}

.footer-btn {
  border-radius: 20px !important;
  background-color: #1292ee !important;
}

ul.mxr__footer-links {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mxr__footer-top {
  padding: 40px 0;
}

.mxr_footer-social-icons {
  align-items: center;
  margin-top: 1.5rem;
}

.mxr_footer-social-icons span {
  border: 1px solid #fff;
  padding: 8px 12px;
  border-radius: 33px;
  width: 43px;
  height: 43px;
  margin-right: 10px;
}

.mxr__footer-copyright {
  border-top: 1px solid #fff;
  padding: 20px 0 0 20px;
  margin-top: 10px;
  text-align: center;
}

.mxr__footer-subscribe {
  margin-top: 1.5rem;
}

.mxr_footer-social-icons {
  align-items: center;
  margin: 1.5rem 0;
}

.mxr__footer-subscribe input[type="submit"] {
  width: 100%;
  background-color: #3393ab;
  border-style: solid;
  border-radius: 4px !important;
  border-width: 0px;
  padding: 12px;
  color: aliceblue;
  margin-top: 30px;
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 15px !important;
  font-family: "Poppins" !important;
}

.mxr__footer-subscribe input.form-control {
  width: 100%;
  border-radius: 0px !important;
  border-width: 0px;
  padding: 12px;
  font-size: 13px;
}

.fusion-footer .mailpoet_spacer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  width: 100%;
}

.mxr__footer a:hover {
  color: #fff !important;
}

.mxr__footer-top p {
  margin-top: 15px;
}

.mxr__footer-copyright .nav-link {
  display: inline-block;
}

.mxr__footer-top li {
  padding: 3px 0px;
}

.mxr__footer.mxr__section {
  padding-bottom: 0;
}

@media screen and (max-width: 767px) {
  .mxr__footer-top {
    padding: 30px 30px;
  }
}

.mxr__footer-copyright p {
  font-size: 0.8rem;
} */

/* ////////////////////////////////////////////////////////////////////////////////////// */

.mxr__footer {
  background: #303030;
  color: #fff;
  padding: 20px;
}

.mxr__footer p,
.mxr__footer a {
  color: #fff;
}

.mxr__footer a {
  font-size: 14px;
  font-weight: 300;
}

.mxr__footer-subscribe button.mxr__section-btn {
  width: 100%;
}

.footer-btn {
  border-radius: 6px !important;
  background-color: #1292ee !important;
}

ul.mxr__footer-links {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mxr__footer-top {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mxr_footer-social-icons {
  align-items: center;
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  margin-left: -62px;
}

.mxr_footer-social-icons span {
  border: 1px solid #fff;
  padding: 8px 12px;
  border-radius: 6px;
  width: 43px;
  height: 43px;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.mxr__footer-copyright {
  border-top: 1px solid #fff;
  padding: 20px 0 0 20px;
  margin-top: 10px;
  text-align: center;
  font-size: 0.8rem;
}

.mxr__footer-subscribe {
  margin-top: 1.5rem;
  width: 100%;
  max-width: 400px;
  /* display: flex;
    flex-direction: column;
    align-items: center; */
}

.mxr__footer-subscribe input[type="submit"] {
  width: 100%;
  background-color: #3393ab;
  border-style: solid;
  border-radius: 4px !important;
  border-width: 0px;
  padding: 12px;
  color: aliceblue;
  margin-top: 30px;
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 15px !important;
  /* font-family: "Poppins" !important; */
}

.mxr__footer-subscribe input.form-control {
  width: 100%;
  border-radius: 6px !important;
  border-width: 0px;
  padding: 12px;
  font-size: 13px;
}

.fusion-footer .mailpoet_spacer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  width: 100%;
}

.mxr__footer a:hover {
  color: #fff !important;
}

.mxr__footer-top p {
  margin-top: 15px;
}

.mxr__footer-copyright .nav-link {
  display: inline-block;
  margin-left: 5px;
}

.mxr__footer-top li {
  padding: 3px 0px;
}

.mxr__footer.mxr__section {
  padding-bottom: 0;
}

.footerLogo {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .mxr__footer-top {
    padding: 30px 15px;
    flex-direction: column;
    align-items: center;
  }

  .mxr_footer-social-icons {
    margin: 1.5rem 0;
    justify-content: center;
  }

  .mxr_footer-social-icons span {
    margin-right: 5px;
    width: 38px;
    height: 38px;
  }

  .mxr__footer-subscribe input.form-control {
    font-size: 12px;
  }

  .mxr__footer-subscribe input[type="submit"] {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .mxr__footer-top {
    padding: 20px 10px;
  }

  .mxr_footer-social-icons span {
    width: 35px;
    height: 35px;
    padding: 6px 10px;
  }

  .mxr__footer-subscribe {
    padding: 0 10px;
  }

  .mxr__footer-subscribe input.form-control {
    font-size: 11px;
  }

  .mxr__footer-subscribe input[type="submit"] {
    font-size: 13px;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .mxr_footer-social-icons {
    margin-left: 0px !important;

  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .mxr__footer-subscribe {
    /* margin-left: -40px; */

  }

  .mxr_footer-social-icons {

    margin-left: -128px !important;
  }
}

@media (min-width: 300px) and (max-width: 600px) {
  .mxr_footer-social-icons {
    margin-left: -156px !important;

  }


}