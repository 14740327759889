.event {
    border-bottom: 1px solid #eceeed;
}

.eventimg {
    height: 270px;
    margin-top: 80px;
    border-radius: 20px;
}

.EventDetailIcon {

    margin-left: -35px;
    margin-right: 10px;
}

.eventTitleName {
    width: 630px;
}