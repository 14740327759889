.insightSection {
    padding: 3rem 0;
}

.stat-item {
    background: white;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    padding: 12px;
}

.stat-item:hover {
    transform: translateY(-10px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
}

.icon-circle {
    width: 80px;
    height: 80px;
    background-color: #1292ee;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0 auto;
}

.statInsightIcon {
    font-size: 2.5rem;
    color: white;
}

.stat-percentage {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
}

.stat-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
}

.card-hover {
    transition: transform 0.3s ease-in-out;
}

.card-hover:hover {
    transform: scale(1.05);
}

.equal-height {
    min-height: 300px;
}

.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.text-content {
    flex-grow: 1;
}

.InsightFIrstCard {
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
}

/* /////////////////////////////////////////////////////////////////////////// */
/* tabs css  */
.custom-tabs .nav-link.active {
    background-color: #1292ee !important;
    /* color: #ffffff !important; */
    border-color: #1292ee;
}

/* Customize hover state for tabs */
.custom-tabs .nav-link {
    /* color: #1292ee; */
}

.custom-tabs .nav-link:hover {
    background-color: #d0eaff;
    color: #1292ee;
}

.keyStatsPara {
    padding: 3px;
    border-radius: 5px;
}