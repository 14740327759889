.mxr__advance2 img {
  width: 791px;
}

.mxr__advance2 .vertical-timeline-element-content.bounce-in {
  display: flex;
  position: RELATIVE;
}

.icon_timeline {
  /* background: #1292ee; */
  border: 2px solid #1292ee;
  height: 60px;
  /* display: inline-flex; */
  width: 60px;
  border-radius: 50%;
  /* padding: 18px; */
  color: #1292ee;
  text-align: center;
  margin-bottom: 0px;
  margin-right: 20px;
  transition: transform 0.3s ease, background-color 0.3s ease;
}



/* .mxr__advance2 .vertical-timeline-element-content-arrow {
  border-color: #619e85;
  left: 20px;
  --webkit-transition-duration: 350ms;
  animation-duration: 350ms;
  display: block;
  border-left-style: solid;
  border-left-width: 1px;
  position: absolute;
  border-right: 0.5px solid #1292ee !important;
  height: 120px;
} */
.last .vertical-timeline-element-content-arrow {
  border: unset !important;
}

@media screen and (max-width: 767px) {
  .mxr__advance2 img {
    display: none;
  }
}

.advance_Features_heading h4:hover {
  /* color: #000; */
}

.icon_timeline:hover {
  transform: scale(1.1);
}

.verticalPadding {
  margin-top: 90px;
}

@keyframes slide {
  from {
    transform: translateX(4px);
  }

  to {
    transform: translateX(-4px);
  }
}

.advance_Features_heading {
  margin-bottom: 15px;
}


.vscards {
  padding: 30px;
  height: 300px;
  text-align: start;
  color: black;

}

.benefits {
  text-align: start;
  /* color: white */
}