.mxr__advance {
  background-color: #1292ee;
}

.bg_3steps_icon {
  background: #ffffff;
  height: 54px;
  display: inline-flex;
  width: 54px;
  border-radius: 50%;
  padding: 6px;
  color: #1292ee;
  text-align: center;
  margin-bottom: 19px;
  margin-right: 20px;
  transition: 1s;
}

.bg_3steps h5 {
  text-align: center;
  color: #fff;
  font-weight: 600;
}

.bg_3steps p {
  color: #fff;
  text-align: center;
}

.bg_3steps .col-md-4.col-sm-12 {
  text-align: center;
  padding: 40px;
}

.bg_3steps h3.mxr__section-title {
  color: #fff;
  margin-bottom: 1%;
}

.bg_3steps_icon:first-child:hover {
  width: 56px;
}

.keyFeatures {
  background-image: url('../../assets/icons/blue-background2.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px;
}

.keyFeatures h5 {
  text-align: center;
  color: #fff;
  font-weight: 600;
}

.keyFeatures p {
  color: #fff;
  text-align: center;
}

.keyFeatures .col-md-4.col-sm-12 {
  text-align: center;
  padding: 40px;
}

.keyFeatures h3.mxr__section-title {
  color: #fff;
  margin-bottom: 1%;
}

.tabColor {
  padding: 30px;
}