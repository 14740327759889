.mxr__industries-icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    list-style: none;
}

.mxr__industries-icon_box {
    width: 20%;
    text-align: center;
    margin-bottom: 20px;
}

.mxr__industries-icon_box img {
    max-width: 70px;
    margin: 16px 0 20px 0;
}

.mxr__industries-icon_box img:hover {
    transform: scale(1.1);
    /* transition: transform 0.3s ease; */
    transition: all ease 1s;
    transition-behavior: normal;
    transition-duration: 1s;
    transition-timing-function: ease;
    transition-delay: 0s;

}

.mxr__industries-icon_box h4 {
    color: var(--color-text);
    font-size: 16px;
    font-weight: 700;
}

@media screen and (max-width: 767px) {
    .mxr__industries-icon_box {

        text-align: center;
    }

    .mxr__whoweare.mxr__section {
        padding: 50px 30px 0px 30px !important;
    }

    .mxr__industries-icon_box {
        width: 50%;
        text-align: center;
        margin-bottom: 20px;
    }

    ul.row.mxr__industries-icons {
        padding-left: 0;
    }

    .mxr__industries-icon_box h4 {
        color: var(--color-text);
        font-size: 14.5px;
        font-weight: 400;
    }

}