.iconColor {
    color: #1292ee;
}

.iconTitle {
    font-weight: bold !important;
    color: black !important;
}

.mxr__process-desktop {
    width: 300px;
    /* margin-left: 100px; */
}

@media (min-width:300px) and (max-width:450px) {
    .studybot {
        height: 345px !important;
    }

    .studybotDiv {
        height: 370px !important;
    }
}

.Ai-Image {
    width: 551px;
}

.Ai-Image2 {
    border-radius: 20px;
    margin-right: 150px;
    margin-top: 40px;
}

.tranformSection {
    background-color: rgb(255, 255, 255);
}

.tranformImg {
    width: 560px;
    position: relative;
    margin-left: 321px;
    margin-bottom: 75px;
    border-radius: 15px;
}

.generativeSectionAi2 {
    padding-bottom: 0px !important;
}