.mxr__stats {
    width: 100%;
    position: relative;
    min-height: 60vh;
    background-image: url('../../assets/All-Images/image999.jpg');
    /* Background image */
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    /* Make sure the image covers the container */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
}

.mxr__stats-numbers {
    position: relative;
    z-index: 10;
    width: 100%;
    margin-left: 70px;
}

.mxr__stats-number-sec {
    height: auto;
    overflow: visible;
    background: rgb(255, 255, 255);
    padding: 30px;
    color: #000;
    border-radius: 10px;
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 23px;
}

.mxr__stats-number-icon img {
    width: 90px;
    margin-bottom: 10px;
    transition: transform 1.5s ease;
}


.mxr__stats-number-icon img:hover {
    transform: scale(1.1);
    /* transition: transform 0.3s ease; */
    transition: all ease 1s;
    transition-behavior: normal;
    transition-duration: 1s;
    transition-timing-function: ease;
    transition-delay: 0s;
    /* transition: transform 0.6s cubic-bezier(0.25, 0.1, 0.25, 1); */
}

@media screen and (max-width: 767px) {
    .mxr__stats {
        background: #1292ee !important;
    }

    .mxr__stats-numbers {
        padding: 57px !important;

    }

    .mxr__stats-number-sec {
        background: rgb(255, 255, 255);
        /* Adjusted for a more opaque background */
        border-right: unset;
        color: #000;
        /* Change text color to black for better readability */
        height: auto;
        overflow: visible;
        padding: 30px;
        margin-bottom: 8px;
        margin: 10px;
        width: 23% !important;
    }
}

.mxr__stats-number-txt h4 {
    color: #1292ee !important;
}

/* @media (min-width: 992px) {
    .col-lg-3 {
        flex: 0 0 auto;
        width: 22% !important;
        height: 250px;
    }
} */


@media screen and (max-width: 1024px) {
    .mxr__stats-numbers {
        margin-left: 0;
        padding: 70px;
    }
}

@media screen and (max-width: 992px) {
    .mxr__stats-numbers {
        margin-left: 0;
        padding: 70px;
    }

    .col-md-3 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .statCard {
        flex: 0 0 auto;
        width: 23% !important;
    }
}

@media screen and (min-width: 992px) {


    .col-lg-3 {
        flex: 0 0 auto;
        width: 23% !important;
    }
}

@media screen and (max-width: 768px) {
    .mxr__stats {
        background: #1292ee !important;
        background-size: cover;
        padding: 20px;
    }

    .mxr__stats-number-sec {
        margin: 10px 0;
        padding: 20px;
    }

    .mxr__stats-number-icon img {
        width: 70px;
    }
}

@media screen and (max-width: 576px) {
    .mxr__stats-number-sec {
        width: 100% !important;
        margin: 10px 0;
        padding: 15px;
    }

    .mxr__stats-number-icon img {
        width: 50px;
    }

    .mxr__stats-number-txt h3 {
        font-size: 1.5em;
    }

    .mxr__stats-number-txt h4 {
        font-size: 1em;
    }
}