.mxr__techno-icons {
    background: white;
    text-align: center;
    box-shadow: 0px 0px 35px rgb(0 0 0 / 10%);
    border-radius: 5px;
    height: 188px;
}
.mxr__techno-icons {
    background: white;
    text-align: center;
    box-shadow: 0px 0px 35px rgb(0 0 0 / 10%);
    border-radius: 5px;
    height: 188px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.mxr__techno .col-lg-3 {
    margin-bottom: 33px;
}
