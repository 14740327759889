.mxr__features {
    background-color: #f8faff;
    padding: 50px 0 !important;
}

.mxr__features .card {
    text-align: center;
    margin-bottom: 20px;
    align-items: center;
    padding: 20px;
    border-radius: 0.8rem;
    border-color: #1292ee !important;

}

.mxr__features .card img {
    width: fit-content;
    margin: 5px 0 20px 0;
    width: 80px;
}

.mxr__features .card .card-title {
    color: #383838;
    font-size: 18px;
    font-weight: 800 !important;
    margin-bottom: 20px;
}

.mxr__features .card .card-text {
    color: #777;
    /* margin-bottom: 20px; */
    font-weight: 300;
}

.mxr__features .card .card-body {
    padding: 0;
}

.mxr__features .card-body p.card-text {
    min-height: 75px;
}

.mxr__features .card .card-link {
    color: var(--color-white-bg-link);
    font-weight: 700;
}

.features_text_color {
    color: #1292ee;
    text-decoration: auto !important;
    font-weight: 700;
}

.mxr__section-title {
    margin: 0px;
    color: black;
    font-size: 2em !important;
    line-height: 1.5;
    text-align: left;
    font-weight: 500;
    padding-bottom: 1.5rem;
    margin-top: 19px;
    text-transform: uppercase;
}

.solutionIcons:hover {
    transform: scale(1.1);
    /* transition: transform 0.3s ease; */
    transition: all ease 1s;
    transition-behavior: normal;
    transition-duration: 1s;
    transition-timing-function: ease;
    transition-delay: 0s;
}