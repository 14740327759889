.aboutSection {
    padding: 0;
}

.about-us-section {
    padding: 50px 0 40px 0;
    background-color: #fff;
    position: relative;
    margin-bottom: 0px;

}

.about-us-container {
    text-align: center;
    max-width: 1300px;
    margin: 0 auto;
}

.about-us-container h2 {
    font-size: 2em;
    margin-bottom: 20px;
    margin-top: 10px;
}

.about-us-container p {
    font-size: 1.2em;
    margin-bottom: 50px;
}

.about-us-content {
    display: flex;
    align-items: center;
    justify-content: right;
}

.about-us-image img {
    max-width: 100%;
    height: auto;
}

.about-us-benefits {
    text-align: left;
    max-width: 600px;
    margin-top: 28px;
}

.about-us-benefits h3 {
    font-size: 2em;
    margin-bottom: 20px;
}

.about-us-benefits ul {
    list-style: none;
    padding: 0;
}

.about-us-benefits ul li {
    display: flex;
    align-items: center;
    font-size: 1.1em;
    margin-left: -23px;
}

.about-us-benefits ul li img {
    margin-right: 10px;
    max-width: 76px;
    height: auto;
    margin-bottom: 9px;
}

.benefits-bottom {

    border-bottom: 2px solid #1292EE;
    width: fit-content;
}

.about-us-images {
    position: relative;
    width: 50%;
    max-width: 600px;
}

.about-us-image {
    position: absolute;
    width: 50%;
    transition: 0.3s ease-in-out;
}

.about-us-image:nth-child(1) {
    left: 0;
    top: 247px;
    z-index: 1;
}

.about-us-image:nth-child(2) {
    left: 187px;
    top: 192px;
    z-index: 2;
}

.backgroundDot {
    position: absolute;
    margin-top: 123px;
    margin-left: 218px;
}

/* original */

/* .external-image {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    width: 300px;
    height: 100%;
    background-image: url('../../assets/icons/dot-image-2.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
} */

.external-image {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    /* Ensure it stays in the background */
    width: 300px;
    height: auto;
    background-image: url('../../assets/icons/dot-image-2.png');
    background-size: contain;
    /* Ensure the image scales down properly */
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.5;
    /* Optional: reduce opacity for a more subtle effect */
}






@media (max-width: 1024px) {
    .about-us-container h2 {
        font-size: 2.2em;
    }

    .about-us-container p {
        font-size: 1.1em;
    }

    .about-us-content {
        flex-direction: column;
        align-items: center;
    }

    .about-us-benefits {
        margin-top: 520px;
        max-width: 90%;
        margin-bottom: -150px;
    }

    .backgroundDot {
        margin: 20px auto;
        max-width: 200px;

    }



}


/* For small screens (Phones) */
@media (max-width: 768px) {
    .about-us-section {
        padding: 20px 0;
    }

    .about-us-container h2 {
        font-size: 2em;
    }

    .about-us-container p {
        font-size: 1em;
    }

    .about-us-content {
        flex-direction: column;
        align-items: center;
    }

    .about-us-image {
        position: static;
        margin-top: 79px;
        margin-left: 92px !important;
    }


    .about-us-benefits {
        /* max-width: 100%; */
        text-align: left;
        margin-top: 240px !important;
        margin-bottom: 0px;
    }


    .about-us-benefits h3 {
        font-size: 1.5em;
    }

    .about-us-benefits ul li {
        font-size: 1em;
        margin-left: 3px;
    }

    .external-image {
        display: none;
    }

    .about-us-image:nth-child(1) {
        position: absolute;
        z-index: 1;
        margin-left: 35px !important;
    }

    .about-us-image:nth-child(2) {
        margin-left: 57px;
        z-index: 2;
    }
}

/* For extra small screens (Small Phones) */
@media (max-width: 768px) {
    .external-image {
        width: 150px;
        top: auto;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
}

@media (max-width: 480px) {
    .external-image {
        width: 100px;
        top: auto;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
}

@media (min-width: 800px) and(max-width: 1024px) {
    .about-us-image {
        position: absolute;
        width: 50%;
        transition: 0.3s ease-in-out;
        margin-left: 107px;
    }

    .backgroundDot {
        margin: 20px auto;
        max-width: 200px;
        margin-left: 320px;
        margin-top: 80px;
    }
}


@media (min-width: 359px) and (max-width: 400px) {
    .dotimage {
        width: 22rem;
        margin-top: 15px;
        margin-left: 12px;
    }
}


@media (min-width: 1020px) and (max-width:1030px) {
    .about-us-image {
        width: 70% !important;
        margin-top: 15px;
    }

    .dotimage {
        margin: 5rem 0 0 24rem
    }
}

.mxrAbout {
    margin-left: 306px;
    margin-top: -91px;
    width: 470px;
}

@media (min-width: 1080px) and (max-width:1400px) {

    .mxrAbout {
        margin-left: 306px;
        margin-top: -91px;
        width: 470px;
    }

    .about-us-content {
        flex-direction: row;
    }

    .about-us-benefits {
        margin-top: 420px;
        max-width: 90%;
        margin-bottom: -90px;
        margin-top: 0px !important;
    }

    .about-us-image {
        margin-top: -115px;
    }
}