.bg_3steps_icon {
  background: #ffffff;
  height: 54px;
  display: inline-flex;
  width: 54px;
  border-radius: 50%;
  padding: 12px;
  color: #1292ee;
  text-align: center;
  margin-bottom: 19px;
  margin-right: 20px;
}

.bg_3steps h5 {
  text-align: center;
  color: #fff;
  font-weight: 600;
}

.bg_3steps p {
  color: #fff;
  text-align: center;
}

.bg_3steps .col-md-4.col-sm-12 {
  text-align: center;
  padding: 40px;
}

.bg_3steps h3.mxr__section-title {
  color: #fff;
  margin-bottom: 1%;
}

.bg_3steps .col-md-6.col-sm-12 {
  text-align: center;
  padding: 40px;
}

/* .bg_3steps {
    background: #1292ee;
} */
.bg_3steps {
  /* background-image: url(../../assets/All-Images/blue-bg.png); */
  background-image: url(../../assets/icons/blue-bg22.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}