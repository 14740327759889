.vertical-tabs {
  display: flex;
}

.tabs {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ddd;
  padding-right: 20px;
}

.tab-button {
  background: none;
  border: none;
  padding: 10px;
  text-align: left;
  font-size: 1em;
  cursor: pointer;
  outline: none;
  color: black;
  width: 260px
}

.tab-button.active {
  color: #00aaff;
  font-weight: bold;
  border-left: 2px solid #1292ee;
  background-color: #f8faff;
  width: 270px
}

.tab-content {
  padding: 20px;
  width: 100%;
}

.tab-content h2 {
  margin-top: 0;
  color: #00aaff;
}

.express-interest {
  background-color: #00aaff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
}


.accordion-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.accordion {
  border: 1px solid #ddd;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.accordion:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.accordion-header {
  font-size: large !important;
  font-weight: bold;
  color: #1292ee;
  background-color: white;
  padding: 15px;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  user-select: none;
  /* Prevent text selection on click */
}

.accordion-content {
  display: none;
  padding: 15px;
  background-color: #fff;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
}

.accordion.active .accordion-header {
  border-bottom: 2px solid #1292ee;

}

.accordion.active .accordion-content {
  display: block;
  max-height: 300px;
  /* Adjust based on content size */
  opacity: 1;

}

.accordion-content p {
  margin: 0;
}

@media (max-width:450px) {
  .textimgcustom {
    width: 387px;
  }


}

.VsBottom {
  border-right: 1px solid #1292ee;
  ;
}