.MRstatistics-section {
    padding: 50px;
    background-color: #ffffff;
    text-align: center;
}

.MRstatistics-section h2 {
    font-size: 2em !important;
    /* margin-bottom: 30px; */
}

.MRstatistics-container {
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.stat-item {
    text-align: center;
    width: 23%;
}

.stat-item h3 {
    font-size: 2em;
    color: #1292ee !important;
    margin-bottom: 10px;
}

.stat-item p {
    font-size: 1em;
    color: #666;
}

.stat-icon {
    width: 80px !important;
    margin-bottom: 10px;
}

@media (max-width: 767px) {
    .MRstatistics-container {
        flex-direction: column;
    }

    .stat-item {
        width: 100%;
        margin-bottom: 20px;
    }
}