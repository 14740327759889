.mxr__research {
    background-image: url('../../assets/icons/blue-background2.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.research-left {
    padding-top: 81px !important;
}

.research-left h2 {
    /* font-size: 45px; */
    font-size: 2em;
    color: white !important;
    font-weight: 700;
}

.mxr-research-secright {
    color: #fff;
    padding-top: 93px;
}

.mxr-research-secright p {
    color: #fff;
}

.mxr__research-border1 {
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
}

.mxr__research-border2 {
    border-bottom: 1px solid #fff;
}

.mxr__research-border3 {
    border-right: 1px solid #fff;
}

.research-left p {
    color: white;
}

.mxr__research-border1,
.mxr__research-border2,
.mxr__research-border3,
.mxr__research-border4 {
    padding-top: 20px;
    padding-bottom: 10px;
}

.mxr__research-borders-sec h6 {
    font-weight: 700;
    margin-top: 20px;
    color: white;
    font-size: larger;
}

.mxr__research-borders-sec .mxr__research-icon {
    text-align: center;
    padding-bottom: 16px;
}

.mxr__research-icon img {
    width: 103px;
}

.mxr__research-borders-sec .mxr__research-heading h4 {
    font-size: 18px;
    line-height: 1.28;
    font-weight: 800;
    text-align: center;
}

.mxr__research-border1,
.mxr__research-border2,
.mxr__research-border3,
.mxr__research-border4 {
    padding-bottom: 24px;
    padding-top: 20px;
}

@media screen and (max-width: 767px) {
    .mxr__research-border1 {
        border-bottom: unset;
        border-right: 0;
    }

    .mxr__research-border2 {
        border-bottom: unset;
    }
}

@media (min-width: 700px) and (max-width: 1024px) {
    .research-left p {
        color: white;
        width: 90vw;
    }

    .researchimgRight {
        margin-top: 344px;
    }

    .research-left h2 {
        margin-top: -40px;
        inline-size: max-content;
    }

    .researchPlus {
        margin-top: 70px !important;
    }
}

@media (min-width:300) and (max-width: 767px) {
    .research-left h2 {

        inline-size: max-content;
    }

    .research-left p {
        color: white;
        width: 77vw !important;
    }
}

/* ipad */
@media (min-width:1024px) and (max-width: 1366px) {
    .research-left h2 {
        margin-top: -40px;
        inline-size: max-content;
    }

    .researchPlus {
        margin-top: 70px !important;
    }

}