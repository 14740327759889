
.mxr__solution{
    background-color: #f5f5f5;
}
.mxr__solution .card {
    text-align: center;
    margin-bottom: 20px;
    align-items: center;
    padding: 20px;
    border-radius: 0.8rem;
}
.mxr__solution .card img{
    width: fit-content;
    margin: 20px 0;
}
.mxr__solution .card .card-title{
    color: #383838;
    font-size: 18px;
    font-weight: 800;
}
.mxr__solution .card .card-text{
    color: #777;
    margin-bottom: 20px;
    font-weight: 300;
}
.mxr__solution .card .card-body{
    padding: 0;
    height: 180px;
}
.mxr__solution .card .card-link{
    color: var(--color-white-bg-link);
    font-weight:700;
}
