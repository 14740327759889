.mxr__innerbanner {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.internal_page_banner>div {
    background-position: center center;
    background-repeat: no-repeat;
    border-width: 0px 0px 0px 0px;
    border-color: #3e3e3e;
    border-style: solid;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 722px;
    align-items: center;
    display: flex;
    flex-direction: column;
    /* height: 530px; */
    justify-content: center;
    position: relative;
    z-index: 1;
}

.internal_page_banner.landing_page_banner>div {
    background-position: center center;
    background-repeat: no-repeat;
    border-width: 0px 0px 0px 0px;
    border-color: #3e3e3e;
    border-style: solid;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 700px;
}

.internal_page_banner h1 {
    margin: 0px;
    color: rgb(255, 255, 255);
    font-size: 50px;
    line-height: 1.4;
    font-weight: 750;
    text-transform: uppercase;
    text-align: center;
}

.mxr__vision .mxr__section-title {
    padding-bottom: 1rem;
}

.internal_page_banner p {
    color: #fff;
    font-size: 25px;
    line-height: 1.6;
    font-weight: 400;
    text-align: center;
}

.left_internal_page.col-lg-5.col-md-5 {
    padding-top: 8%;
}

@media screen and (max-width: 767px) {
    .internal_page_banner>div {
        /* background: #1292eed6; */
    }

    .left_internal_page.col-lg-5.col-md-5 {
        padding-top: 50px;
        margin-top: 0px;
        padding-right: 30px;
        padding-bottom: 50px;
        margin-bottom: 0px;
        padding-left: 30px;
    }
}