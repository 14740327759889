.carousel-caption {
    color: #7e7e7e;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    text-align: center;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: block;
    text-align: center !important;
}

.slide span.sr-only {
    display: none;
}

.carousel.slide.carousel-fade .carousel-caption {
    position: absolute;
    top: 51%;
    left: 50%;
    padding: 0;
    z-index: 5;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
}

.slide .carousel-caption h3 {
    font-weight: 750;
    font-size: 50px !important;
    line-height: 49px !important;
    font-family: Nunito !important;
    /* text-transform: uppercase; */
    color: #fff;
    text-align: center;
}

.titleSpan {
    font-size: 25px;
}

.slide .carousel-caption p {
    color: #fff;
}

.carousel-fade .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 942px;
    margin-top: -220px;
}

.mxr__section-btn {
    display: block;
    margin: 20px auto 0;
    text-align: center;
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
}

@media screen and (max-width: 767px) {
    .carousel-fade h3.mxr__section-title {
        font-weight: 900 !important;
        font-size: 19px !important;
        /* background: #00000094; */
        padding: 10px;
        width: 100%;
        float: left;
        line-height: 28px !important;
        margin-bottom: 30px;
    }

    .mxr__section {
        padding: 50px 30px;
    }

    .mxr__promise .mxr__section-title {
        line-height: 34px;
    }

    .mxr__promise.mxr__section p {
        margin-bottom: 23px;
    }

    .mxr__promise-content {
        padding: 0;
    }

    .mxr__promise.mxr__section {
        text-align: center;
    }

    .mxr__promise .mxr__section-title {
        font-size: 33px;
        color: #383838;
        font-weight: 600;
        text-align: center;
    }

    .carousel-fade .carousel-inner {
        position: relative;
        width: 100%;
        overflow: hidden;
        height: 550px !important;
    }

    .carousel.slide.carousel-fade .carousel-caption {
        position: absolute;
        top: 50%;
        padding: 0;
        z-index: 5;
        transform: translateY(-50%);
        text-align: center;
        width: 90%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .carousel-fade video {
        height: 550px !important;
    }

    .carousel-fade button {
        margin-top: 15px;
    }

    .carousel-fade img.d-block.w-100 {
        height: 300px;
    }
}


.discoverbtn {
    width: 293px;
    font-size: larger !important;
    height: 49px;
}

.discoverbtn:hover {
    background-color: #107fcf !important;
    cursor: pointer;
}

/* 
.nunito-<slide> {
    font-family: "Nunito", sans-serif;
    font-optical-sizing: auto;
    font-weight: <weight>;
    font-style: normal;
} */


.carousel-indicators .active {
    opacity: -999 !important;
}

.carousel-indicators ol {
    opacity: -999 !important;
}

.carousel-indicators li {
    opacity: -999 !important;
}

.XrDemoBtn {
    pointer-events: auto;
}