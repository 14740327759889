/* .slideCard {
    max-width: 250px !important;
    height: 483px !important;
} */

/* .slidebody {
    width: 100%;
} */

.custom-carousel .slick-prev,
.custom-carousel .slick-next {
    z-index: 1;
    color: #ffffff;
}

.custom-carousel .slick-prev:hover,
.custom-carousel .slick-next:hover {
    color: #78baf5;
}

.custom-carousel .slick-dots li button:before {
    color: #ffffff;
}

.custom-carousel .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #ffffff;
}

/* .slideIcons {
    margin-left: 33%;
    text-align: center;
} */

.slideIcons {
    position: absolute;
    bottom: 11px;
    left: 36%;
    /* Move it horizontally to the center */
    /* transform: translateX(-50%); */
    /* Adjust the centering */
    text-align: center;
}

.slideIcons:hover {
    transform: scale(1.1);

}