.mxr__hireus h3.mxr__section-title {
    text-align: left;
}

.mxr__hireus svg {
    color: #1292ee;
    margin-right: 12px;
}

.mxr__hireus p {
    color: #777777 !important;
}

.mxr__help-main {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3%;
    gap: 30px;
}

.mxr__help-main .card {
    padding: 20px;
    text-align: center;
}

.mxr__help-icon img {
    margin: 10px 0 20px;
    width: 80px;
}

@media screen and (max-width: 767px) {
    .mxr__hireus img {
        width: 100%;
    }
}

@media (min-width: 350px) and (max-width: 440px) {
    .mxr__help-main {
        flex-wrap: wrap;
    }

    .mxr__help-icon img {
        margin: 10px 0 20px;
        width: 80px;
    }
}