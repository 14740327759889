.mxr__header-navbar {
    transition: background-color 0.3s ease;
    z-index: 1000;
    position: fixed;
    width: 100%;
    padding: 10px;
    position: -webkit-sticky;
}



.mxr__header-navbar.transparent {
    background-color: transparent !important;
    /* border-bottom: 1px solid #ddd; */
}

.mxr__header-navbar.scrolled {
    background-color: #1292ee !important;
    /* background-color: #323232 !important; */
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
.mxr__header {
    /* background-color: var(--color-primary-bg); */
    background-color: #1292ee;
    /* background-color: transparent !important; */
}

.navbar-light .navbar-toggler-icon {
    background-image: url(../../assets/images/3_lines.png) !important;
}

.mxr__header-top {
    border-bottom: 1px solid rgba(247, 247, 247, 0.21);
    color: #fff;
}

.mxr__header-align-left {
    line-height: 44px;
}

.mxr__header-separator {
    display: inline-block;
    padding: 0 6px;
}

.mxr__header-align-right {
    text-align: right;
    padding: 8px;
}

.mxr__header .mxr__header-navbar .navbar-nav a {
    color: #fff;
}

.mxr__header .mxr__header-navbar .navbar-nav div .nav-link {
    padding: .5rem 1rem;
}

.mxr__header .mxr__header-navbar .navbar-nav a.active {
    border-bottom: 3px solid #fff;
}

.mxr__header-align-right svg {
    margin: 7px 10px 10px;
}

.dropdown a.nav-link:hover {
    color: #fff !important;
}

.mxr__header-navbarmegamenu a.nav-link:hover {
    color: red !important;
}

.mxr__header-align-left span.mxr__header-phone {
    font-size: 14px;
}

.mxr__header-navbar .navbar-collapse {
    align-items: center;
    flex-basis: unset !important;
    flex-grow: unset !important;

}

#basic-navbar-nav.navbar-collapse.collapse li.menu-items {
    padding: 18px 30px 18px 30px;
    OVERFLOW: VISIBLE;
    Z-INDEX: 9999;
    LIST-STYLE: none;
    /* font-size: 14px; */
}

.menu-items svg {
    position: absolute;
    right: -6px;
    top: 11px;
}

.mxr__header-navbar .menu-items span.arrow:before {
    content: "\f0d7";
    font-size: 14px;
    font-family: 'FontAwesome';
    padding-left: 2px;
}

span.arrow:before {
    content: "\f0d7";
}

.mxr__header-navbar .navbar-collapse li.menu-items:last-child {
    padding-right: 0 !important;
}

.mxr__header .dropdown li.menu-items {
    padding: 0px 30px 0px 0px !important;
}

.dropdown a.nav-link {
    font-size: 15px;
    color: #424242 !important;
}

.mxr__header-navbarmegamenu li.menu-items:hover {
    background: #ffffff00 !important;
    color: #1292ee !important;
}

.mxr__header nav.navbar.navbar-expand-lg.navbar-light {
    padding: 0;
}

.mxr__header-navbar ul.dropdown.show {
    display: block;
}

.mxr__header-navbar ul.dropdown {
    display: none;
}

.mxr__header-navbar ul.dropdown {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: none;
    position: absolute;
    /* top: 100%; */
    /* left: ; */
    z-index: 9999;
    min-width: 200px;
    padding: 10px 0;
}

.mxr__header-align-right a {
    color: #fff;
}

.mxr__header-navbar ul.mxr__header-navbarmegamenu {
    display: none;
    background-color: #f1f1f1;
    z-index: 999;
    position: absolute;
    width: auto;
    max-width: none;
    top: 73px;
    left: 0;
}

.mxr__header-navbarmegamenu ul {
    display: table-cell;
    padding: 5px 10px 6px 8px;
    list-style-type: none;
}

.mxr__header-navbar div#basic-navbar-nav {
    position: relative;
}

.navbar-light .menu-items div {
    COLOR: #FFF;
    height: auto;
    position: relative;
}

.mxr__header .mxr__header-navbar .navbar-nav a.active {
    border-bottom: UNSET !IMPORTANT;
    COLOR: #FFF;
    inline-size: max-content;
}

.dropdown a {
    COLOR: #060606 !IMPORTANT;
}

.mxr__header-navbar ul.dropdown.show {
    padding-left: 0;
}

.mxr__header-navbar ul.dropdown {
    padding-left: 0;
}

.dropdown li.menu-items:hover {
    background: #1292ee;
}

.dropdown a.nav-link:hover {
    color: #fff;
}

.mxr__header-navbarmegamenu a.nav-link:hover {
    color: #1292ee !important;
}

.mxr__header-navbarmegamenu a.nav-link {
    padding-left: 0 !important;
}

ul.dropdown.show.mxr__header-navbarmegamenu {
    list-style-type: unset;
}

.mxr__header-navbarmegamenu h3 {
    color: #333;
    font-family: inherit;
    font-size: 16px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: normal;
    text-align: left;
    margin: 0px 0px 5px 0px;
    padding: 0px 0px 3px 0px;
    vertical-align: top;
    display: block;
    visibility: inherit;
    border-top: 0px solid #ddd;
    border-left: 0px solid #ddd;
    border-right: 0px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.dropdown .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #0d6efd;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.mxr__header-logo {
    width: 237px !important;
}

@media screen and (max-width: 767px) {
    .navbar-brand img.mxr__header-logo {
        width: 196px;
        padding-top: 11px;
    }

    .mxr__header-top .col-md-6.col-sm-12.mxr__header-align-left {
        text-align: center;
    }

    .mxr__header-navbarmegamenu ul {
        display: inline-table;
        padding: 5px 10px 6px 8px;
        list-style-type: none;
    }

    .mxr__header-navbarmegamenu a.nav-link {
        width: 128px;
        color: #8f8f8f !important;
    }

    ul.dropdown.show.mxr__header-navbarmegamenu {
        background: #fff;
    }

    .mxr__header-top .col-md-6.col-sm-12.mxr__header-align-right {
        text-align: center;
    }

    .navbar-light .navbar-toggler {
        border-color: unset !important;
        color: rgba(255, 255, 255, 0);
    }

    .mxr__header-navbar .navbar-light .menu-items div {
        COLOR: #fff;
        height: auto;
        padding: 0;
    }

    .mxr__header div#basic-navbar-nav {
        position: absolute;
        top: 0;
        left: 0;
        background: #1292ee;
        z-index: 1111;
        width: 80%;
    }

    #basic-navbar-nav.navbar-collapse.collapse li.menu-items {
        OVERFLOW: VISIBLE;
        Z-INDEX: 9999;
        LIST-STYLE: none;
        padding: 0 !important;
    }

    .navbar-nav li.menu-items>div {
        padding: 0 !important;
        border-bottom: unset !important;
    }

    .menu-items svg {
        position: absolute;
        right: 10px;
        top: 11px;
    }

    .navbar-nav .menu-items {
        padding: 0px 0px !important;
        border-bottom: 1px solid #fff;
    }

    .navbar-nav .menu-items a.nav-link {
        padding: 0;
    }

    .mxr__header-navbar .nav-link {
        display: block;
        padding: 7px 18px 7px 18px !important;
        color: #0d6efd;
        text-decoration: none;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
    }

    .mxr__header-navbar ul.dropdown {
        background-color: #ffffff !important;
    }

    .dropdown a.nav-link {
        font-size: 15px;
        color: #8f8f8f !important;
    }

    a.nav-link {
        padding: 0 !important;
    }

    .mxr__header-navbar ul.dropdown {
        background-color: #1292ee;
        display: none;
        max-width: none;
        position: unset !important;
        top: unset;
        width: auto;
        z-index: unset;
    }

    .navbar-nav .nav-link {
        padding-left: 10px;
        padding-right: 0;
    }

    .mxr__header .dropdown li.menu-items {
        padding: 0 !important;
    }

    .mxr__header-navbar ul.dropdown.show {
        margin-bottom: 0;
    }

    .dropdown a.nav-link.active {
        color: #1292ee !important;
    }
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////// */
.mxr__header-navbar ul.dropdown.show {
    display: block;
}

.mxr__header-navbar ul.dropdown li.menu-items {
    padding: 10px 20px;
    list-style: none;
    transition: background-color 0.3s ease;
}

.mxr__header-navbar ul.dropdown li.menu-items:hover {
    background-color: #1292ee;
}

.mxr__header-navbar ul.dropdown li.menu-items a.nav-link {
    color: #333;
    font-size: 16px;
    text-decoration: none;
    display: block;
    width: 100%;
    transition: color 0.3s ease;
}

.mxr__header-navbar ul.dropdown li.menu-items a.nav-link:hover {
    color: #1292ee;
}

.mxr__header-navbar ul.dropdown li.menu-items a.nav-link.active {
    color: #1292ee !important;
    font-weight: bolder;
}

.mxr__header-navbar ul.dropdown li.menu-items a.nav-link.active:hover {
    color: #ffffff !important;
    font-weight: bolder;
}

.mxr__header-navbarmegamenu {
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.mxr__header-navbarmegamenu h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 2px solid #1292ee;
    text-transform: uppercase;
}

.mxr__header-navbarmegamenu ul {
    padding: 0;
    margin: 0;
}

.mxr__header-navbarmegamenu ul li.menu-items {
    padding: 8px 10px;
    list-style: none;
}

.mxr__header-navbarmegamenu ul li.menu-items a.nav-link {
    color: #666;
    font-size: 15px;
    display: block;
    padding: 5px 10px;
    border-radius: 4px;
    transition: background-color 0.3s ease-in, color 0.3s ease;
}

.mxr__header-navbarmegamenu ul li.menu-items a.nav-link:hover {
    background-color: #1292ee;
    color: #fff;
}

@media screen and (max-width: 767px) {
    .mxr__header-navbar ul.dropdown {
        background-color: #1292ee;
        position: static;
        padding: 10px 0;
        box-shadow: none;
    }

    .mxr__header-navbar ul.dropdown li.menu-items {
        padding: 10px 20px;
    }

    .mxr__header-navbar ul.dropdown li.menu-items a.nav-link {
        font-size: 16px;
        padding: 10px 15px;
    }
}

/* .mxr__header-navbar .navbar-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
} */