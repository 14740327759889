 .whatwecan {
     width: 578px;
 }

 .vrCaseImg {
     width: 497px;
     position: relative;
     margin-top: -43px;
     margin-right: 30px;
 }

 @media (min-width:300px) and (max-width:450px) {
     .vrCaseImg {
         width: 334px;
         position: relative;
         margin-top: -48px;
         margin-right: 30px;
     }

 }

 @media (min-width:1080px) and (max-width:1400px) {
     .vrCaseImg {
         width: 334px;
         position: relative;
         margin-top: -28px !important;
         margin-right: 30px;
     }

 }