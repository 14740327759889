.trusted-by-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    justify-content: center;
}

.trusted-by-header hr {
    flex: 1;
    border: none;
    border-top: 2px solid #838383;
    margin: 0 10px;
}

.trusted-by-container {
    position: relative;
    display: flex;
    align-items: center;
}

.trusted-by-logos::-webkit-scrollbar {
    display: none;
}

.trusted-by-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.trustLogos {
    max-width: 150px;

}

.mxr__section {
    padding: 40px 0;
}

.trusted-by-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    justify-content: center;
}

.trusted-by-header hr {
    flex: 1;
    border: none;
    border-top: 2px solid #838383;
    margin: 0 10px;
}

.trusted-by {
    /* text-align: center; */
}

.trusted-by-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 180px;
    padding: 20px 0;
}

.trustLogos {
    max-width: 120px;
    height: auto;
    transition: transform 1.5s ease-in-out;
}

.trustLogos:hover {
    transform: scale(1.1);
    /* transition: transform 0.3s ease; */
    transition: all ease 1s;
    transition-behavior: normal;
    transition-duration: 1s;
    transition-timing-function: ease;
    transition-delay: 0s;
}

@media (max-width: 768px) {
    .trustLogos {
        max-width: 80px;
    }

    .trusted-by-logos {
        gap: 20px;
    }
}

@media (max-width: 480px) {
    .trustLogos {
        max-width: 60px;
    }

    .trusted-by-logos {
        flex-direction: column;
        gap: 45px;
    }
}


.trustedSlide .slick-prev,
.trustedSlide .slick-next {
    z-index: 1;
    color: #1292ee !important;
}

.trustedSlide .slick-prev:hover,
.trustedSlide .slick-next:hover {
    color: #0c6bbf !important;
}

.trustedSlide .slick-dots li button:before {
    color: #1292ee !important;
}

.trustedSlide .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #1292ee !important;
}